<template>
  <div class="container">
    <div class="row border-bottom">
      <div class="col fs-4 p-3">
        {{ message }}
      </div>
    </div>
    <div
      class="row p-2 border-bottom"
      v-for="(report, index) in reports"
      :key="index"
    >
      <div class="col">
        {{ report["label"] }}
      </div>
      <div class="col">
        <a
          :class="
            disabled.includes(index)
              ? 'btn btn-primary disabled'
              : 'btn btn-primary'
          "
          :href="report['url']"
          role="button"
          target="_blank"
          @click="() => disableButton(index)"
          >Stampa</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "print-dialog",
  props: ["message", "reports"],
  data() {
    return { disabled: [] };
  },
  methods: {
    disableButton(idx) {
      this.disabled = [...this.disabled, idx];
    },
  },
};
</script>

<style scoped>
</style>