import http from "../utils/http-common";

class CessioninvoicesDataService {
  getAll() {
    return http.get("/cession-invoice");
  }

  get(id) {
    return http.get(`/cession-invoice/${id}`);
  }

  create(data) {
    return http.post("/cession-invoice", data);
  }

  update(id, data) {
    return http.put(`/cession-invoice/${id}`, data);
  }

  delete(id) {
    return http.delete(`/cession-invoice/${id}`);
  }
}

export default new CessioninvoicesDataService();